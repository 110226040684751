<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)"
              ref="form_add_or_update"
              class="repeater-form" >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Select Month') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('date')"
                  rules="required"
              >
                <b-form-input v-model="attendance_date" required type="month"
                                   name="attendance_date" placeholder="select month"
                                   :state="errors.length > 0 ? false:null"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <template>
          <b-table-simple v-if="attendance_date" hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Student No</b-th>
              <b-th class="text-center"  :colspan="1">Name</b-th>
              <b-th class="text-center" v-for="(day,key) in days" :key="key">{{ day+1 }}</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(at,k) in stds" :key="k">
                <b-th class="text-left">{{ cadetIdByID(at.id) }}</b-th>
                <b-th class="text-left">{{ studentNameByID(at.id) }}</b-th>
                <b-th class="text-center" v-for="(day,key) in days" :key="key">{{ atStatus(at, day + 1) }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTableSimple,BThead,BTr,BTh,BTbody,
  BFormGroup,BFormInput,
  BForm,BRow, BCol,BCardText,
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
export default {
  name:'ViewAttendance',
  components: {
    BCardCode,BFormGroup,BFormInput,
    BForm,BRow, BCol,BCardText,ValidationProvider, ValidationObserver,
    BTableSimple,BThead,BTr,BTh,BTbody,
  },
  directives: {
    Ripple,
  },
  props: {
    classes:{required:true},
    // version:{required:true},
    // shift:{required:true},
    wing:{required:true},
    student_group:{required:true},
    section:{required:true},
    academic_year:{required:true},
    subject:{required:true},
  },
  data() {
    return {
      moment,
      required,
      attendance_date:null,
      days:[],
    }
  },
  methods:{
    /*method for get all days in selected month*/
    getDays(){
      this.days=Array.from(Array(moment(this.attendance_date).daysInMonth()).keys());
    },
    /*
    * method for get attendance status
    * */
    atStatus(std,day){
      let ff = this.attendances.find(item=>item.student_id===std.id && item.attendance_date === moment(this.attendance_date+'-'+day).format('Y-MM-DD'));
      if(ff){
        return this.attendanceStatusByID(ff.attendance_status_id);
      }
      else return null;
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    /*get this employee students*/
    stds(){
      return this.$store.getters.students.filter(item=>{
        if(item.classes_id=== parseInt(this.classes) && item.wing_id===parseInt(this.wing) &&
            item.student_group_id===parseInt(this.student_group) && item.section_id===parseInt(this.section) &&
            item.academic_year_id===parseInt(this.academic_year)
        ) return item;
      });
    },
    attendances(){
      return this.$store.getters.e_student_attendances.filter(item=>{
        if(item.classes_id=== parseInt(this.classes) && item.wing_id===parseInt(this.wing) &&
            item.student_group_id===parseInt(this.student_group) && item.section_id===parseInt(this.section) &&
            item.academic_year_id===parseInt(this.academic_year)
        ) return item;
      });
    },
    ...mapGetters(['students','studentIdByID','studentNameByID','attendance_statuses','attendanceStatusByID','e_student_attendances', 'cadetIdByID']),
  },
  created() {
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
    if(this.attendance_statuses.length <1) this.$store.dispatch('GET_ALL_ATTENDANCE_STATUS');
    if(this.e_student_attendances.length <1) this.$store.dispatch('GET_ALL_E_STUDENT_ATTENDANCE');
  },
  watch: {
    attendance_date(){
      this.getDays();
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>